import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:4000',  // Usar 4000 por defecto
});

// Interceptar respuestas para manejar errores de autenticación
api.interceptors.response.use(
  (response) => {
    // Si la respuesta es exitosa, retornamos la respuesta
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Redirigir al usuario al login cuando el token haya expirado
      alert('Sesión expirada, por favor ingrese nuevamente.');

      // Limpiar cualquier token almacenado (si lo tienes en localStorage)
      localStorage.removeItem('authToken');

      // Redirigir a la página de login
      window.location.href = '/login';
    }
    
    // Propagamos el error si no es 401, para ser manejado por otras partes del código
    return Promise.reject(error);
  }
);

export default api;
