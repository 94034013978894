import React from 'react';
import PropTypes from 'prop-types';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import '../../screen/Establishment.css';

// Fix marker icon issue with Leaflet in React
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const EstablishmentDetails = ({ establishment }) => {
  // Verifica si las coordenadas existen
  const hasCoordinates = establishment.latitude && establishment.longitude;

  // Genera la URL de Google Maps con la latitud y longitud
  const googleMapsUrl = hasCoordinates
    ? `https://www.google.com/maps?q=${establishment.latitude},${establishment.longitude}`
    : '#';

  const displayAddress = establishment.noAddressNumber
    ? `${establishment.address}, S/N`
    : `${establishment.address}, ${establishment.addressNumber}`;

  const handleOpenGoogleMaps = () => {
    if (hasCoordinates) {
      window.open(googleMapsUrl, '_blank');
    }
  };
  


  return (
    <div className="visits-container">
      <h2>Detalles del Establecimiento</h2>
      <p data-testid="establishment-name"><strong>Nombre:</strong> {establishment.name}</p>
      <p data-testid="establishment-address"><strong>Dirección:</strong> {displayAddress}</p>
      <p data-testid="establishment-neighborhood"><strong>Barrio:</strong> {establishment.neighborhood || 'No disponible'}</p>
      <p data-testid="establishment-city"><strong>Localidad:</strong> {establishment.city}</p>
      <p data-testid="establishment-province"><strong>Provincia:</strong> {establishment.province}</p>
      <p data-testid="establishment-postal-code"><strong>Código Postal:</strong> {establishment.postalCode || 'No disponible'}</p>
      <p data-testid="establishment-industry"><strong>Rubro:</strong> {establishment.industry?.name || 'No disponible'}</p>
      <p data-testid="establishment-contact-info"><strong>Información de Contacto:</strong> {establishment.contactInfo}</p>

      <div className="map-container">
        <h3>Ubicación en el mapa</h3>
        {hasCoordinates ? (
          <>
            <MapContainer center={[establishment.latitude, establishment.longitude]} zoom={15} style={{ height: '300px', width: '100%' }}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={[establishment.latitude, establishment.longitude]}>
                <Popup>
                  {displayAddress} <br /> {establishment.city}, {establishment.province}
                </Popup>
              </Marker>
            </MapContainer>
            <button data-testid="google-maps-button" onClick={handleOpenGoogleMaps} className="google-maps-button">
              Ver en Google Maps
            </button>
          </>
        ) : (
          <p data-testid="no-coordinates-message" className="no-coordinates-message">
            Para visualizar el mapa, es necesario buscar la dirección exacta del establecimiento
            desde el modal de creación o actualización de establecimiento, disponible en la
            página de establecimientos.
          </p>
        )}
      </div>
    </div>
  );
};

EstablishmentDetails.propTypes = {
  establishment: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    addressNumber: PropTypes.string,
    noAddressNumber: PropTypes.bool,
    neighborhood: PropTypes.string,
    city: PropTypes.string.isRequired,
    province: PropTypes.string.isRequired,
    postalCode: PropTypes.string,
    industry: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    contactInfo: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }).isRequired,
};

export default EstablishmentDetails;