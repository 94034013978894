import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import api from '../../api/axiosConfig';
import VisitDetailsModal from '../../components/visits/VisitDetailsModal';
import PeriodicVisitDetailsModal from '../../components/visits/PeriodicVisitDetailsModal';
import AttachFormModal from '../../components/visits/AttachFormModal';
import ReceiptModal from '../../components/visits/ReceiptModal';
import PropTypes from 'prop-types';
import './CalendarView.css';

const CalendarView = () => {
  const [visits, setVisits] = useState([]);
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [showAttachFormModal, setShowAttachFormModal] = useState(false);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [receiptVisit] = useState(null);
  const [selectedEstablishmentId, setSelectedEstablishmentId] = useState(null);
  const [showPeriodicVisitModal, setShowPeriodicVisitModal] = useState(false);

  // **Mover fetchVisits fuera del useEffect**
  const fetchVisits = async () => {
    try {
      const token = localStorage.getItem('authToken');

      // Obtener visitas normales
      const responseVisits = await api.get('/api/visits', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Obtener visitas periódicas
      const responsePeriodicVisits = await api.get('/api/establishments/periodic-visits', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Formatear visitas normales
      const visitsData = responseVisits.data.map(visit => {
        if (!visit.date) {
          console.warn(`Fecha inválida para la visita con ID: ${visit._id}`);
          return null;
        }

        const formattedDate = visit.date.split('T')[0]; // Extrae la fecha sin tiempo

        return {
          id: visit._id,
          title: `${visit.establishmentId?.name || 'Sin nombre'} - ${visit.type}`,
          start: formattedDate,
          end: formattedDate,
          allDay: true,
          visitDetails: visit,
          color: visit.isPaid ? 'green' : 'red',
          isPeriodicVisit: false,
        };
      }).filter(Boolean);

      // Formatear visitas periódicas
      const periodicVisitsData = responsePeriodicVisits.data.map(visit => {
        return visit.months.map(month => {
          const monthIndex = getMonthFromAbbreviation(month);
          const visitDate = new Date(new Date().getFullYear(), monthIndex, visit.dayOfMonth);

          // Ajustar la fecha para evitar problemas de zonas horarias
          const year = visitDate.getFullYear();
          const monthNumber = (visitDate.getMonth() + 1).toString().padStart(2, '0');
          const day = visitDate.getDate().toString().padStart(2, '0');
          const formattedDate = `${year}-${monthNumber}-${day}`;

          return {
            id: visit._id,
            title: `${visit.establishmentId?.name || 'Sin nombre'} - Visita periódica`,
            start: formattedDate,
            end: formattedDate,
            allDay: true,
            visitDetails: visit,
            color: '#800080',
            isPeriodicVisit: true,
            establishmentId: visit.establishmentId?._id,
          };
        });
      }).flat();

      setVisits([...visitsData, ...periodicVisitsData]);
    } catch (error) {
      console.error('Error al obtener las visitas:', error);
    }
  };

  useEffect(() => {
    fetchVisits();
  }, []);

  const handleEventClick = async (info) => {
    try {
      const token = localStorage.getItem('authToken');

      if (info.event.extendedProps.isPeriodicVisit) {
        // Si es una visita periódica, abrir el modal de visitas periódicas
        setSelectedVisit(null);
        setSelectedEstablishmentId(info.event.extendedProps.establishmentId);
        setShowPeriodicVisitModal(true);
      } else {
        // Si es una visita regular, obtener los detalles de la visita
        setSelectedEstablishmentId(null);
        const response = await api.get(`/api/visits/${info.event.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data) {
          setSelectedVisit(response.data);
          setShowPeriodicVisitModal(false);
        }
      }
    } catch (error) {
      console.error('Error al obtener detalles de la visita:', error);
    }
  };

  const handleCloseVisitDetails = () => {
    setSelectedVisit(null);
  };

  const handleClosePeriodicVisitModal = () => {
    setShowPeriodicVisitModal(false);
    setSelectedEstablishmentId(null);
  };

  const handleCloseAttachFormModal = () => {
    setShowAttachFormModal(false);
    setSelectedVisit(null);
  };

  return (
    <div className='calendar-container' data-testid="calendar-container">
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={visits}
        eventClick={handleEventClick}
        locale={esLocale}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,dayGridDay',
        }}
        data-testid="full-calendar"
      />

      {selectedVisit && !showAttachFormModal && !showPeriodicVisitModal && (
        <VisitDetailsModal
          visit={selectedVisit}
          onClose={() => {
            handleCloseVisitDetails();
            fetchVisits(); // Refrescar las visitas después de cerrar el modal
          }}
          onStatusChanged={() => {
            fetchVisits(); // Refrescar las visitas después de un cambio en el estado
          }}
          data-testid="visit-details-modal"
        />
      )}

      {showPeriodicVisitModal && selectedEstablishmentId && (
        <PeriodicVisitDetailsModal
          establishmentId={selectedEstablishmentId}
          onClose={handleClosePeriodicVisitModal}
          data-testid="periodic-visit-details-modal"
        />
      )}

      {showAttachFormModal && selectedVisit && (
        <AttachFormModal
          visit={selectedVisit}
          onClose={handleCloseAttachFormModal}
          onFormAttached={() => fetchVisits()}
          data-testid="attach-form-modal"
        />
      )}

      {showReceiptModal && receiptVisit && (
        <ReceiptModal visit={receiptVisit} onClose={() => setShowReceiptModal(false)}
          data-testid="receipt-modal" />
      )}
    </div>
  );
};

// Función para obtener el índice correcto del mes
const getMonthFromAbbreviation = (month) => {
  const months = {
    ENE: 0,
    FEB: 1,
    MAR: 2,
    ABR: 3,
    MAY: 4,
    JUN: 5,
    JUL: 6,
    AGO: 7,
    SEP: 8,
    OCT: 9,
    NOV: 10,
    DIC: 11,
  };
  return months[month.toUpperCase()];
};

CalendarView.propTypes = {
  onVisitClick: PropTypes.func,
};

export default CalendarView;