import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom'; // Importar useNavigate y useLocation
import './Header.css';
import profileIcon from '../../assets/profile-icon.png';
import settingsIcon from '../../assets/preferences-icon.png';
import logoutIcon from '../../assets/logout-icon.png'; // Agrega un ícono para cerrar sesión (puedes cambiar la ruta)
import hamburgerIcon from '../../assets/hamburger-icon.png';
import AddVisitModal from '../visits/AddVisitModal';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showVisitModal, setShowVisitModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation(); // Obtener la ubicación actual

  const toggleMenu = () => {
    setMenuOpen((prevState) => !prevState);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  // Cerrar el menú si el usuario hace clic fuera
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown-menu') && !event.target.closest('.hamburger-button')) {
        setMenuOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleAddVisitClick = () => {
    setShowVisitModal(true);
  };

  const handleCloseModal = () => {
    setShowVisitModal(false);
  };

  const handleVisitAdded = (establishmentId) => {
    const establishmentVisitsPath = `/dashboard/establecimiento/${establishmentId}/visits`;
    if (location.pathname === establishmentVisitsPath) {
      navigate(`${establishmentVisitsPath}?updated=${Date.now()}`);
    } else {
      navigate(establishmentVisitsPath);
    }
  };

  // Manejo de cierre de sesión
  const handleLogout = () => {
    const confirmLogout = window.confirm('¿Seguro que deseas cerrar sesión?');
    if (confirmLogout) {
      localStorage.removeItem('authToken'); // Elimina el token de autenticación
      localStorage.removeItem('userName'); // También puede eliminar otros datos almacenados en el localStorage si es necesario
      navigate('/login'); // Redirige al usuario a la página de login
    }
  };

  return (
    <header className="header">
      <div className="header-left">
        <h1>BioSafeApp</h1>
        <button data-testid="add-visit-button" className="add-visit-button" onClick={handleAddVisitClick}>
          Agregar Visita
        </button>
      </div>
      <div className="header-right">
        <button data-testid="hamburger-button" className="hamburger-button" onClick={toggleMenu}>
          <img src={hamburgerIcon} alt="Menu" />
        </button>

        {menuOpen && (
          <div data-testid="dropdown-menu" className="dropdown-menu">
            <Link data-testid="profile-link" to="/profile" className="dropdown-item" onClick={closeMenu}>              <img src={profileIcon} alt="Perfil" className="dropdown-icon" />
              Perfil
            </Link>
            <Link data-testid="settings-link" to="/settings" className="dropdown-item" onClick={closeMenu}>
              <img src={settingsIcon} alt="Preferencias" className="dropdown-icon" />
              Configuración
            </Link>
            <div data-testid="logout-button" className="dropdown-item logout" onClick={handleLogout}>
              <img src={logoutIcon} alt="Cerrar sesión" className="dropdown-icon" />
              Cerrar sesión
            </div>
          </div>
        )}
      </div>

      {showVisitModal && (
        <AddVisitModal
          data-testid="add-visit-modal"
          onClose={handleCloseModal}
          onVisitAdded={handleVisitAdded}
        />
      )}
    </header>
  );
};

export default Header;