import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './SuggestedRoutes.css';
import Sidebar from '../components/common/Sidebar';
import api from '../api/axiosConfig';
import mapIcon from '../assets/mapIcon.png';
import viewRoutesIcon from '../assets/view-routes-icon.png';
import PropTypes from 'prop-types'; // Importa PropTypes para la validación de props

// Configuración del icono de marcador predeterminado de Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const MapCenter = ({ position }) => {
  const map = useMap();
  if (position) map.setView(position, 15);
  return null;
};

const SuggestedRoutes = () => {
  const [location, setLocation] = useState(null);
  // eslint-disable-next-line
  const [locationError, setLocationError] = useState(null);
  const [todayVisits, setTodayVisits] = useState([]);
  const [upcomingVisits, setUpcomingVisits] = useState([]);
  const [plannedRoute, setPlannedRoute] = useState([]);
  const [selectedEstablishment, setSelectedEstablishment] = useState(null);
  const [showNoCoordinatesAddRouteModal, setShowNoCoordinatesAddRouteModal] = useState(false);
  const [showRouteModal, setShowRouteModal] = useState(false);
  const [showNoCoordinatesModal, setShowNoCoordinatesModal] = useState(false);
  const mapSectionRef = useRef(null);

  const handleShowRouteModal = () => {
    setShowRouteModal(true);
  };

  const closeRouteModal = () => {
    setShowRouteModal(false);
  };

  const handleMapButtonClick = (visit) => {
    if (visit.establishmentId.lat && visit.establishmentId.lng) {
      setLocation({
        lat: visit.establishmentId.lat,
        lng: visit.establishmentId.lng,
        name: visit.establishmentId.name,
        address: visit.establishmentId.address,
        addressNumber: visit.establishmentId.addressNumber || '',
        neighborhood: visit.establishmentId.neighborhood || '',
      });
      mapSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else {
      setSelectedEstablishment(visit.establishmentId);
      setShowNoCoordinatesModal(true);
    }
  };

  useEffect(() => {
    const fetchVisitsAndEstablishments = async () => {
      try {
        const token = localStorage.getItem('authToken');

        // Request para visitas programadas y establecimientos
        const responseVisits = await api.get('/api/visits', { headers: { Authorization: `Bearer ${token}` } });
        const responseEstablishments = await api.get('/api/establishments', { headers: { Authorization: `Bearer ${token}` } });

        // Request adicional para visitas periódicas
        const responsePeriodicVisits = await api.get('/api/establishments/periodic-visits', { headers: { Authorization: `Bearer ${token}` } });

        const establishmentsMap = new Map();
        responseEstablishments.data.forEach((establishment) => {
          establishmentsMap.set(establishment._id, establishment);
        });

        const today = new Date();
        today.setUTCHours(0, 0, 0, 0);
        const oneWeekLater = new Date(today);
        oneWeekLater.setDate(today.getDate() + 7);

        const formattedTodayVisits = [];
        const formattedUpcomingVisits = [];

        // Función para clasificar las visitas en hoy y próximas visitas
        const classifyVisits = (visitList, isPeriodic = false) => {
          visitList.forEach((visit) => {
            if (isPeriodic) {
              const currentMonth = today.toLocaleString('default', { month: 'short' }).toUpperCase();
              const visitDay = visit.dayOfMonth;
              const isToday = visit.months.includes(currentMonth) && visitDay === today.getDate();

              const establishmentData = establishmentsMap.get(visit.establishmentId._id);
              if (establishmentData) {
                visit.establishmentId.lat = establishmentData.latitude;
                visit.establishmentId.lng = establishmentData.longitude;
                visit.establishmentId.name = establishmentData.name;
                visit.isPeriodicVisit = true;
              }

              if (isToday) {
                formattedTodayVisits.push(visit);
              } else if (
                visit.months.includes(currentMonth) ||
                visit.months.includes(oneWeekLater.toLocaleString('default', { month: 'short' }).toUpperCase())
              ) {
                formattedUpcomingVisits.push(visit);
              }
            } else {
              const visitDate = new Date(visit.date);
              visitDate.setUTCHours(0, 0, 0, 0);

              const establishmentData = establishmentsMap.get(visit.establishmentId._id);
              if (establishmentData) {
                visit.establishmentId.lat = establishmentData.latitude;
                visit.establishmentId.lng = establishmentData.longitude;
                visit.establishmentId.name = establishmentData.name;
              }

              if (visitDate.toDateString() === today.toDateString()) {
                formattedTodayVisits.push(visit);
              } else if (visitDate > today && visitDate <= oneWeekLater) {
                formattedUpcomingVisits.push(visit);
              }
            }
          });
        };

        // Clasificamos visitas programadas y periódicas
        classifyVisits(responseVisits.data);
        classifyVisits(responsePeriodicVisits.data, true);

        setTodayVisits(formattedTodayVisits);
        setUpcomingVisits(formattedUpcomingVisits);
      } catch (error) {
        console.error('Error al obtener visitas y establecimientos:', error);
      }
    };

    fetchVisitsAndEstablishments();
  }, []);

  const requestLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const initialLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            name: 'Punto de partida inicial',
            isInitialLocation: true,
          };
          setLocation(initialLocation);
          setLocationError(null);

          setPlannedRoute((prevRoute) => {
            if (!prevRoute.find((item) => item.isInitialLocation)) {
              return [initialLocation, ...prevRoute];
            }
            return prevRoute;
          });
        },
        () => {
          setLocationError('No se pudo obtener la ubicación. Ingrésala manualmente.');
        }
      );
    } else {
      setLocationError('Tu navegador no soporta la geolocalización.');
    }
  };

  const haversineDistance = (lat1, lon1, lat2, lon2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371;
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  const findClosestPoint = (currentLocation, points) => {
    let closestPoint = null;
    let minDistance = Infinity;

    points.forEach(point => {
      if (point && point.lat != null && point.lng != null) {
        const distance = haversineDistance(
          currentLocation.lat,
          currentLocation.lng,
          point.lat,
          point.lng
        );

        console.log(`Distancia desde ${currentLocation.name || currentLocation.establishmentId.name} a ${point.name || point.establishmentId.name}:`, distance);

        if (distance < minDistance) {
          minDistance = distance;
          closestPoint = point;
        }
      }
    });

    console.log("Punto más cercano encontrado:", closestPoint ? (closestPoint.name || closestPoint.establishmentId.name) : "Ninguno");
    return closestPoint;
  };

  const calculateOptimizedRoute = (startingPoint, points) => {
    let route = startingPoint ? [startingPoint] : [];
    let remainingPoints = points.filter(point => point && point.lat != null && point.lng != null && point._id !== startingPoint._id);

    console.log("Calculando ruta, punto de partida inicial:", startingPoint);

    let currentPoint = startingPoint;

    while (remainingPoints.length > 0) {
      const closestPoint = findClosestPoint(currentPoint, remainingPoints);
      if (closestPoint) {
        route.push(closestPoint);
        console.log(`Punto más cercano a ${currentPoint.name || currentPoint.establishmentId?.name}:`, closestPoint.name || closestPoint.establishmentId?.name);
        remainingPoints = remainingPoints.filter(point => point !== closestPoint);
        currentPoint = closestPoint;
      } else {
        break;
      }
    }

    console.log("Ruta optimizada calculada:", route.map(point => point.name || point.establishmentId?.name || "Nombre no disponible"));
    return route;
  };

  const generateGoogleMapsLink = (route) => {
    const baseUrl = "https://www.google.com/maps/dir";
    const uniqueCoordinates = route.reduce((acc, point) => {
      if (point && !acc.some(p => p.lat === point.lat && p.lng === point.lng)) {
        acc.push(point);
      }
      return acc;
    }, []);

    if (uniqueCoordinates.length === 2) {
      // Solo punto de partida y un establecimiento (A -> B)
      const origin = `${uniqueCoordinates[0].lat.toFixed(5)},${uniqueCoordinates[0].lng.toFixed(5)}`;
      const destination = `${uniqueCoordinates[1].lat.toFixed(5)},${uniqueCoordinates[1].lng.toFixed(5)}`;
      return `${baseUrl}/${origin}/${destination}/?entry=ttu`;
    } else if (uniqueCoordinates.length > 2) {
      // Caso con 3 o más puntos (A -> waypoints -> B)
      const routePoints = uniqueCoordinates.map(point => `${point.lat.toFixed(5)},${point.lng.toFixed(5)}`).join('/');

      // Incluir todos los puntos en la URL principal
      return `${baseUrl}/${routePoints}/?entry=ttu`;
    } else {
      // Si no hay suficientes puntos
      return null;
    }
  };

  const optimizedRoute = location ? calculateOptimizedRoute(location, plannedRoute) : [];
  const googleMapsLink = optimizedRoute.length > 1 ? generateGoogleMapsLink(optimizedRoute) : null;

  const handleAddToRoute = (visit) => {
    if (!visit.establishmentId.lat || !visit.establishmentId.lng) {
      // Establecimiento sin coordenadas, abrir modal
      setSelectedEstablishment(visit.establishmentId);
      setShowNoCoordinatesModal(true);
      return;
    }

    setPlannedRoute((prevRoute) => {
      if (!prevRoute.find((routeVisit) => routeVisit._id === visit._id)) {
        return [...prevRoute, { ...visit, lat: visit.establishmentId.lat, lng: visit.establishmentId.lng }];
      }
      return prevRoute;
    });

    if (todayVisits.some((item) => item._id === visit._id)) {
      setTodayVisits((prev) => prev.filter((item) => item._id !== visit._id));
    } else if (upcomingVisits.some((item) => item._id === visit._id)) {
      setUpcomingVisits((prev) => prev.filter((item) => item._id !== visit._id));
    }
  };

  const handleRemoveFromRoute = (visitId) => {
    const visitToRemove = plannedRoute.find((visit) => visit._id === visitId);

    if (visitToRemove) {
      setPlannedRoute((prevRoute) => prevRoute.filter((visit) => visit._id !== visitId));

      if (visitToRemove.isPeriodicVisit) {
        // Si es una visita periódica, regresa a `upcomingVisits`
        setUpcomingVisits((prev) => [...prev, visitToRemove]);
      } else {
        // Si no es periódica, verifica si corresponde a `todayVisits`
        const visitDate = new Date(visitToRemove.date);
        const today = new Date();
        today.setUTCHours(0, 0, 0, 0);
        const oneWeekLater = new Date(today);
        oneWeekLater.setDate(today.getDate() + 7);

        if (visitDate.toDateString() === today.toDateString()) {
          setTodayVisits((prev) => [...prev, visitToRemove]);
        } else if (visitDate > today && visitDate <= oneWeekLater) {
          // Confirmamos que la visita se devuelve correctamente a las próximas visitas
          setUpcomingVisits((prev) => [...prev, visitToRemove]);
        }
      }
    }
  };

  return (
    <div className="suggested-routes-container">
      <Sidebar />
      <div className="content">
        <h2>Rutas Sugeridas</h2>
        {!location && (
          <div className="tooltip-warning">
            Para agregar visitas a la ruta, primero debes proporcionar tu ubicación inicial.
          </div>
        )}
        {!location ? (
          <button data-testid="btn-obtain-location" onClick={requestLocation}>Obtener mi ubicación</button>
        ) : (
          <div data-testid="map-section" ref={mapSectionRef} className="map-section">
            <p>
              <h3>Ubicación inicial establecida:{" "}</h3>

              {location.isInitialLocation
                ? "Punto de ubicación actual"
                : `Establecimiento: ${location.name}, Calle: ${location.address || ""}`}
            </p>
            <MapContainer center={[location.lat, location.lng]} zoom={15} style={{ height: '400px', width: '100%' }}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; OpenStreetMap contributors'
              />
              <MapCenter position={[location.lat, location.lng]} />
              <Marker position={[location.lat, location.lng]} />
            </MapContainer>
          </div>
        )}

        <div className="section-container">
          <h3>Visitas de Hoy</h3>
          {todayVisits.length > 0 ? (
            todayVisits.map((visit) => (
              <div key={visit._id} className="visit-item">
                <p>{visit.establishmentId.name} - {visit.isPeriodicVisit ? 'Visita Periódica' : 'Programada'}</p>
                <div className="button-group">
                  <button
                    className={`map-btn ${!visit.establishmentId.lat || !visit.establishmentId.lng || !location ? 'disabled' : ''}`}
                    onClick={() => handleMapButtonClick(visit)}
                    disabled={!visit.establishmentId.lat || !visit.establishmentId.lng || !location}
                    title={!location ? "Proporciona tu ubicación inicial para habilitar esta función" : "Ver establecimiento en el mapa"}
                  >
                    <img data-testid="btn-view-map-today" src={mapIcon} alt="Ver en el mapa" style={{ width: '20px', height: '20px' }} />
                  </button>
                  <button
                    data-testid="btn-add-to-route-today"
                    className="add-to-route-btn"
                    onClick={() => handleAddToRoute(visit)}
                    disabled={!location}
                  >
                    Agregar a Ruta
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="no-visits">No hay visitas programadas para hoy.</p>
          )}
        </div>

        <div className="section-container">
          <h3>Próximas Visitas (7 días)</h3>
          {upcomingVisits.length > 0 ? (
            upcomingVisits.map((visit) => (
              <div key={visit._id} className="visit-item">
                <p>{visit.establishmentId.name} - {visit.isPeriodicVisit ? 'Visita Periódica' : 'Programada'}</p>
                <div className="button-group">
                  <button
                    data-testid="btn-view-map-upcoming"
                    className={`map-btn ${!visit.establishmentId.lat || !visit.establishmentId.lng || !location ? 'disabled' : ''}`}
                    onClick={() => handleMapButtonClick(visit)}
                    disabled={!visit.establishmentId.lat || !visit.establishmentId.lng || !location}
                    title={!location ? "Proporciona tu ubicación inicial para habilitar esta función" : "Ver establecimiento en el mapa"}
                  >
                    <img src={mapIcon} alt="Ver en el mapa" style={{ width: '20px', height: '20px' }} />
                  </button>
                  <button
                    data-testid="btn-add-to-route-upcoming"
                    className="add-to-route-btn"
                    onClick={() => handleAddToRoute(visit)}
                    disabled={!location}
                  >
                    Agregar a Ruta
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="no-visits">No hay visitas programadas en los próximos 7 días.</p>
          )}
        </div>

        <div className="section-container">
          <h3>Viaje Programado</h3>
          {plannedRoute.length > 0 ? (
            plannedRoute.map((visit, index) => (
              <div key={visit._id} className="visit-item">
                <p>{index + 1}. {visit.isInitialLocation ? 'Punto de partida inicial' : visit.establishmentId?.name || "Nombre no disponible"}</p>
                {!visit.isInitialLocation && (
                  <button
                    data-testid="btn-remove-from-route"
                    className="remove-from-route-btn"
                    onClick={() => handleRemoveFromRoute(visit._id)}
                  >
                    Eliminar
                  </button>
                )}
              </div>
            ))
          ) : (
            <p className="no-visits">No hay visitas en el itinerario.</p>
          )}
        </div>

        {showNoCoordinatesAddRouteModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h4>Establecimiento sin coordenadas</h4>
              <p>Necesitas ir a editar la información del perfil del establecimiento para agregar las coordenadas y verlo en el mapa.</p>
              <button onClick={() => setShowNoCoordinatesAddRouteModal(false)}>Cerrar</button>
              <button onClick={() => window.location.href = `/dashboard/establecimiento/${selectedEstablishment._id}/details`}>
                Ir a perfil de establecimiento
              </button>
            </div>
          </div>
        )}

        {showRouteModal && (
          <div className="modal-overlay">
            <div className="modal-content">
            <button data-testid="btn-close-route-modals" onClick={closeRouteModal}>×</button>
              <div className="modal-header">
                <h4>Ruta Optimizada para Tu Itinerario</h4>
                <p>Basándonos en la distancia entre los establecimientos, sugerimos esta secuencia para tu ruta:</p>
              </div>
              <div className="modal-body">
                <div className="optimized-route-list">
                  {optimizedRoute.map((visit, index) => {
                    console.log('Visit:', visit);
                    return (
                      <p key={visit._id || index}>
                        {index + 1}. {visit.name || visit.establishmentId?.name || "Nombre no disponible"}
                      </p>
                    );
                  })}
                </div>
                <iframe
                  width="100%"
                  height="400"
                  frameBorder="0"
                  style={{ border: 0 }}
                  src={
                    optimizedRoute.length > 2
                      ? `https://www.google.com/maps/embed/v1/directions?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&origin=${optimizedRoute[0].lat},${optimizedRoute[0].lng}&destination=${optimizedRoute[optimizedRoute.length - 1].lat},${optimizedRoute[optimizedRoute.length - 1].lng}&waypoints=${optimizedRoute.slice(1, -1).map(point => `${point.lat},${point.lng}`).join('|')}`
                      : `https://www.google.com/maps/embed/v1/directions?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&origin=${optimizedRoute[0].lat},${optimizedRoute[0].lng}&destination=${optimizedRoute[optimizedRoute.length - 1].lat},${optimizedRoute[optimizedRoute.length - 1].lng}`
                  }
                  allowFullScreen
                ></iframe>
                <button data-testid="btn-view-google-maps-modal" onClick={() => window.open(googleMapsLink, "_blank")}>
                  Ver en Google Maps
                </button>
                <button data-testid="btn-close-route-modal" onClick={closeRouteModal}>Cerrar</button>
              </div>
            </div>
          </div>
        )}

        {googleMapsLink && optimizedRoute.length >= 2 && (
          <div className="info-container" style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
            <img src={viewRoutesIcon} alt="Icono de rutas" className="info-icon" style={{ width: '40px', height: '40px', marginBottom: '10px' }} />
            <div className="info-text">
              <p><strong>¡Información importante!</strong> Nuestro sistema de geolocalización realizará los cálculos para ofrecerte un camino único en tus visitas y así, ganar tiempo y programar mejor tus visitas.</p>
              <p className="click-instruction">Haz click en este botón para conocer la ruta sugerida.</p>
            </div>
            <button data-testid="btn-view-suggested-route" onClick={handleShowRouteModal} style={{ marginTop: '15px' }}>
              Ver en Google Maps
            </button>
          </div>
        )}


        {showNoCoordinatesModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h4>Establecimiento sin coordenadas</h4>
              <p>Necesitas ir a editar la información del perfil del establecimiento para agregar las coordenadas y verlo en el mapa.</p>
              <button data-testid="btn-close-no-coordinates-modal" onClick={() => setShowNoCoordinatesModal(false)}>Cerrar</button>
              <button data-testid="btn-go-to-establishment-profile" onClick={() => window.location.href = `/dashboard/establecimiento/${selectedEstablishment?._id}/details`}>
                Ir a perfil de establecimiento
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Define la validación de props para el componente MapCenter
MapCenter.propTypes = {
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default SuggestedRoutes;